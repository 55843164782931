import Box from "@mui/material/Box";

import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import StickyHeadTable from "Components/Shared/table";
import Wrapper from "Components/Wrapper";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getQueryString, request, toLocaleDate } from "Utils";
import { useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import TransactionMenu from "./menu";
import { TransactionInt, TransactionsFunc } from "./type";
import { useLocation } from "react-router-dom";
import NoContentFound from "Components/Shared/noContentFound";
import TransactionFilter from "./filter_align";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as XLSX from "xlsx";
import { Button } from "@mui/material";

const getWallets = async ({ queryKey }: any) => {
  const [, { pageId, limit, filter }] = queryKey;
  const query = {
    pageId,
    limit,
  };
  const queryParams = filter
    ? `${filter}&pageId=${pageId}&limit=${limit || 10}`
    : getQueryString(query);
  const { data } = await request.get({ url: `/transactions${queryParams}` });
  return data;
};
const Transactions: TransactionsFunc = () => {
  const { toggleToast, toastMessage } = useToast();
  const [pageId, setPageId] = useState(1);
  const [filter, setFilter] = useState("");
  const [limit, setLimit] = useState(10);
  const query = useLocation();
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: [
      "Users",
      {
        pageId,
        limit,

        filter: query.search,
      },
    ],
    queryFn: getWallets,
    retry: 2,
  });
  useEffect(() => {
    const params = new URLSearchParams(filter);
    if (params.get("limit")) {
      setLimit(parseInt(params.get("limit")!, 10));
    }
    if (params.get("pageId")) {
      setPageId(parseInt(params.get("pageId")!, 10));
    }
  }, [filter]);
  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Amount", key: "amount" },
    { minWidth: 50, name: "Currency", key: "currency" },
    { minWidth: 50, name: "Fee", key: "fee" },
    { minWidth: 50, name: "Payment Gateway", key: "paymentGateway" },
    { minWidth: 50, name: "Payment Method", key: "paymentMethod" },
    { minWidth: 50, name: "Purpose", key: "purpose" },
    { minWidth: 50, name: "Status", key: "status" },
    { minWidth: 50, name: "Sender", key: "sender" },
    { minWidth: 50, name: "Receiver", key: "receiver" },
    { minWidth: 20, name: "Created At", key: "createdAt" },
    { minWidth: 50, name: "Type", key: "type" },
    { minWidth: 50, name: "Action", key: "action" },
  ];

  const transactions = data?.transactions?.map(
    (transaction: TransactionInt, index: number) => ({
      index: ++index + limit * (pageId - 1),
      ...transaction,
      createdAt: toLocaleDate(transaction?.createdAt),
      sender: transaction?.metadata?.sender?.name,
      receiver: transaction?.metadata?.receiver?.name,
      amount: `${transaction?.currencySymbol} ${transaction?.amount?.toLocaleString(
        "en"
      )}`,
      fee: `${transaction?.currencySymbol} ${transaction?.fee?.toLocaleString(
        "en"
      )}`,
      action: (
        <TransactionMenu
          transactionId={transaction?.id}
          walletId={transaction?.walletId}
          toggleToast={toggleToast}
        />
      ),
    })
  );
  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      data?.transactions?.map((transaction: TransactionInt) => ({
        "Transaction ID": transaction?.id,
        Amount: transaction?.amount,
        Currency: `${transaction?.currencySymbol}${transaction?.currency}`,
        Fee: `${transaction?.currencySymbol} ${transaction?.fee?.toLocaleString(
          "en"
        )}`,
        "Payment Gateway": transaction?.paymentGateway,
        "Payment Method": transaction?.paymentMethod,
        Purpose: transaction?.purpose,
        Status: transaction?.status,
        Sender: transaction?.metadata?.sender?.name,
        Reciever: transaction?.metadata?.receiver?.name,
        Type: transaction?.type,
        "Created At": toLocaleDate(transaction?.createdAt),
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");
    XLSX.writeFile(workbook, "monirates_transactions.xlsx");
  };
  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Wrapper>
      {data?.transactions?.length ? (
        <Box sx={{ height: "85.5vh", position: "relative" }}>
          {filter ? (
            <Box
              onClick={() => window.location.reload()}
              marginTop="-40px"
              display="flex"
              alignItems="center"
            >
              <ArrowBackIcon
                style={{ fontSize: 40 }}
                sx={{ "&:hover": { cursor: "pointer" } }}
              />
            </Box>
          ) : (
            ""
          )}
          <Box textAlign="right">
            <Button variant="contained" color="primary" onClick={downloadExcel}>
              Download Excel
            </Button>
            <TransactionFilter setFilter={setFilter} filter={filter} />
          </Box>
          <StickyHeadTable
            refetch={refetch}
            data={transactions}
            dataLength={data?.totalTransactions}
            columns={columns}
            setLimit={setLimit}
            limit={limit}
            setPageId={setPageId}
            pageId={pageId}
            pageCount={data.pageCount}
          />
          {Boolean(toastMessage) && (
            <Toast
              open={Boolean(toastMessage)}
              message={toastMessage}
              onClose={() => toggleToast("")}
            />
          )}
        </Box>
      ) : (
        <NoContentFound />
      )}
    </Wrapper>
  );
};

export default Transactions;
