import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
// import { KycCardFunc } from "./type";
import { Link } from "react-router-dom";
import { BdcFeeCardFunc } from "./type";

const BdcFeeCard: BdcFeeCardFunc = ({ type, bdcId, toCurrency }) => {
  const params = {
    type: type.type,
    bdcId: bdcId,
    toCurrency: toCurrency,
  };

  // Convert object to URLSearchParams
  const searchParams = new URLSearchParams(params).toString();

  return (
    <Link
      to={
        bdcId && toCurrency
          ? `/bdc-fee-history/${type.type}?${searchParams}`
          : `/bdc-fee-history/${type.type}`
      }
    >
      <Card>
        <CardContent
          sx={{
            textAlign: "center",
            height: 200,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: 700, fontSize: 24, color: "#333333" }}
            color="text.secondary"
            gutterBottom
          >
            {type.title}
            {}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};
export default BdcFeeCard;
