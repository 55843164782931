import Box from "@mui/material/Box";
import Wrapper from "Components/Wrapper";
import BdcFeeCard from "./card";
import { request } from "Utils";
import { Grid, Stack, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useQuery } from "react-query";

const GetBdcFee = () => {
  //   const [];
  const location = useLocation();
  const [bdcId, setBdcId] = useState<string>(location.state?.bdcId || "");
  const [bdcName, setBdcName] = useState<string>("");
  const [toCurrency, setToCurrency] = useState<string>(
    location.state?.toCurrency || ""
  );
  const getBdc = async () => {
    try {
      const id = bdcId;
      const { data } = await request.get({ url: `/bdc/${id}` });
      setBdcName(data?.name);
      return data;
    } catch (error) {}
  };

  const { data, error, isLoading, refetch } = useQuery({
    // queryKey: ,
    queryFn: getBdc,
    retry: 2,
    // staleTime: 86400000,
  });

  const types = [
    { title: "Debit History", type: "debit" },
    { title: "Credit History", type: "credit" },
  ];
  return (
    <Wrapper>
      <Box pb={4} component="section">
        {bdcId && toCurrency ? (
          <>
            <Stack>
              <p>
                <strong>BDC Id: </strong>
                {bdcName}
              </p>
              <p>
                <strong>To Currency:</strong>
                {toCurrency}
              </p>
            </Stack>
          </>
        ) : (
          <></>
        )}
        <Grid container spacing={6} justifyContent="center">
          {Array.isArray(types) &&
            types?.map((type: any, index: number) => (
              <Grid key={index} item xs={12} sm={4} md={3}>
                <BdcFeeCard type={type} bdcId={bdcId} toCurrency={toCurrency} />
              </Grid>
            ))}
        </Grid>
      </Box>
    </Wrapper>
  );
};
export default GetBdcFee;
