import { MoreVert } from "@mui/icons-material";
import { IconButton, MenuItem } from "@mui/material";
import Menus from "Components/Shared/menu";
import { useDailog, useMenu, useToast } from "Utils/hooks";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import UpdateIcon from "@mui/icons-material/Update";
import { BdcMenuFunc } from "./type";
import ExchangeFeePayment from "./exchangeFeeBalance";
import PaymentDetails from "./paymentDetails";
import UpdateBdcFeeDialog from "Components/BdcFee/updateBdcFee";
import Toast from "Components/Shared/toast";

const BdcMenu: BdcMenuFunc = (props) => {
  const { anchorEl, menuIsOpen, closeMenu, openMenu } = useMenu();
  const { toggleToast, toastMessage } = useToast();
  const exchangeDialog = useDailog();
  const paymentDialog = useDailog();
  const bdcUpdateDialog = useDailog();
  const apiEndpoint = "/bdc/paid-fee";

  return (
    <>
      <IconButton onClick={openMenu}>
        <MoreVert />
      </IconButton>

      <Menus anchorEl={anchorEl} open={menuIsOpen} closeMenu={closeMenu}>
        <div>
          <MenuItem onClick={exchangeDialog.openDialog}>
            <AcUnitIcon /> View Exchange Fee Balance
          </MenuItem>
          <MenuItem onClick={paymentDialog.openDialog}>
            <AcUnitIcon /> View Payment Details
          </MenuItem>
          <MenuItem onClick={bdcUpdateDialog.openDialog}>
            <UpdateIcon /> Update BDC Payment
          </MenuItem>
        </div>
      </Menus>

      <ExchangeFeePayment
        isOpen={exchangeDialog.isOpen}
        closeDialog={exchangeDialog.closeDialog}
        bdcInfo={{
          exchangeFeeBalance: props?.bdcInfo.exchangeFeeBalance,
          _id: props.bdcInfo._id,
        }}
      />

      <PaymentDetails
        isOpen={paymentDialog.isOpen}
        closeDialog={paymentDialog.closeDialog}
        bdcInfo={{
          paymentDetails: props?.bdcInfo.paymentDetails,
          bdcId: props.bdcInfo._id,
        }}
      />

      <UpdateBdcFeeDialog
        isOpen={bdcUpdateDialog.isOpen}
        closeDialog={bdcUpdateDialog.closeDialog}
        refetch={props.refetch}
        toggleToast={toggleToast}
        bdcInfo={{
          bdcId: props.bdcInfo._id,
        }}
      />
      {Boolean(toastMessage) && (
        <Toast
          open={Boolean(toastMessage)}
          message={toastMessage}
          onClose={() => toggleToast("")}
        />
      )}
    </>
  );
};

export default BdcMenu;
