import { useCallback, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import ActionButton from "Components/Shared/button";
import InputField from "Components/Shared/textField";
import DialogComponent from "Components/Shared/dailog";
import Progress from "Components/Shared/circleLoading";
import Box from "@mui/material/Box";
import { request } from "Utils";
import { useForm } from "Utils/hooks";
import { useMutation } from "react-query";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ConfigsInt } from "Components/Configuration/type";
import { UpdateBdcFeeFunc, UpdateBdcFeeInt } from "./type";

const UpdateBdcFeeDialog: UpdateBdcFeeFunc = ({
  isOpen,
  closeDialog,
  toggleToast,
  refetch,
  bdcInfo,
}: UpdateBdcFeeInt) => {
  const { values, getData, reset } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);

  const [countries, setCountries] = useState<Array<ConfigsInt>>();
  const sx = {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    alignItems: "center",
    width: "100%",
  };
  const listCountries = useCallback(async () => {
    //UseCallback is used to stop infinite rerenders
    try {
      const { data } = await request.get({ url: "/configurations" });
      setCountries(data);
    } catch (error) {}
  }, []);
  useEffect(() => {
    const initalBdcId = {
      target: {
        name: "bdcId",
        value: bdcInfo?.bdcId,
        type: "text",
        checked: false,
        id: "",
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>;
    listCountries();
    getData(initalBdcId);
  }, [listCountries]);
  useEffect(() => {
    const countryCurrency = countries?.find((country) => {
      if (country.iso2 == values?.iso2) {
        return country;
      }
    });
    if (!values?.iso2 || values.currency != countryCurrency?.currency) {
      const automaticallySetCurrency = {
        target: {
          name: "currency",
          value: countryCurrency?.currency,
          type: "text",
          checked: false,
          id: "",
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>;
      getData(automaticallySetCurrency);
    } else {
    }
  }, [values.iso2]);

  const mutation = useMutation(
    async () => {
      return await request.post({
        url: "/bdc/paid-fee",
        data: { ...values, bdcId: bdcInfo?.bdcId },
      });
    },
    {
      onSuccess: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
        closeDialog();
        if (refetch) {
          refetch();
        }
        reset();
      },
      onError: ({ message }) => {
        if (toggleToast) {
          toggleToast(message);
        }
        toggleToast(message);
        setIsloading(false);
        reset();
        closeDialog();
      },
    }
  );

  function submitForm(event: React.FormEvent) {
    event.preventDefault();
    setIsloading(true);

    mutation
      .mutateAsync()
      .then(() => {
        closeDialog(); // Close the dialog after successful submission
      })
      .catch(() => {
        // Handle error, dialog will still close
        closeDialog();
      })
      .finally(() => {
        setIsloading(false); // Reset loading state
      });
  }

  const types = ["DEBIT", "CREDIT"];
  return (
    <DialogComponent
      open={isOpen}
      onClose={closeDialog}
      title="Update Bdc payment"
      maxWidth="sm"
    >
      <Stack spacing={3} py={2}>
        <form onSubmit={submitForm}>
          <Stack spacing={3} py={3}>
            <Box sx={sx}>
              <InputField
                type="text"
                label="Bdc Id"
                name="bdcId"
                onChange={getData}
                sx={{ width: { xs: "100%", md: "48%" } }}
                required
                defaultValue={bdcInfo?.bdcId}
                inputProps={{ readOnly: true }}
              />
              <InputField
                type="number"
                label="Amount"
                name="amount"
                onChange={getData}
                sx={{ width: { xs: "100%", md: "48%" } }}
                required
                inputProps={{
                  step: "0.0001", // Allows up to two decimal places
                }}
              />
            </Box>
            <Box sx={sx}>
              <FormControl sx={{ width: { xs: "100%", md: "48%" } }}>
                <InputLabel>Select Country</InputLabel>
                <Select
                  name="iso2"
                  labelId="Country"
                  label="Select Country"
                  defaultValue={""}
                  value={values.country}
                  onChange={(e: any) => getData(e)}
                >
                  {countries?.map((country, index) => (
                    <MenuItem key={`${index}-country`} value={country.iso2}>
                      {country.country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: { xs: "100%", md: "48%" } }}>
                <InputLabel>Select Currency</InputLabel>
                <Select
                  name="currency"
                  labelId="Currency"
                  label="Select Currency"
                  defaultValue={""}
                  inputProps={{ readOnly: true }}
                  value={values.currency || ""}
                  onChange={(e: any) => getData(e)}
                >
                  {countries?.map((country, index) => (
                    <MenuItem
                      key={`${index}-currency`}
                      value={country.currency}
                    >
                      {country.currency}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box
              sx={{
                ...sx,
                rowGap: { xs: "5rem", md: "0rem" },
              }}
            >
              <FormControl sx={{ width: { xs: "100%", md: "100%" } }}>
                <InputLabel>Type</InputLabel>
                <Select
                  name="type"
                  labelId="Type"
                  label="Select type"
                  onChange={(e: any) => getData(e)}
                  defaultValue={""}
                >
                  {types?.map((type, index) => (
                    <MenuItem key={`${index}-type`} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <ActionButton type="submit" variant="contained">
              Submit
              {isLoading && (
                <Progress
                  sx={{
                    width: { xs: "100%", md: "40%" },
                    mb: 2,
                    // color: "#fff", ml: 0.8,
                    backgroundColor: "#606676",
                    "&:hover": {
                      backgroundColor: "#606676",
                    },
                  }}
                  size={18}
                />
              )}
            </ActionButton>
          </Stack>
        </form>
      </Stack>
    </DialogComponent>
  );
};
export default UpdateBdcFeeDialog;
