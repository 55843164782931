//import Stack from "@mui/material/Stack";
import { useDailog, useForm, useMenu, useToast } from "Utils/hooks";
//import ActionButton from "Components/Shared/button";
// import InputField from "Components/Shared/textField";
// import DialogComponent from "Components/Shared/dailog";
// import { useState } from "react";
// import Progress from "Components/Shared/circleLoading";
// import Box from "@mui/material/Box";
// import { request } from "Utils";
// import { useMutation } from "react-query";
import { Button, IconButton, MenuItem } from "@mui/material";
import MoreVert from "@mui/icons-material/MoreVert";
import Menus from "Components/Shared/menu";
import UpdateBdcFeeDialog from "./updateBdcFee";
import { BdcFeeMenuFunc } from "./type";
import Toast from "Components/Shared/toast";

const BdcFeeMenu: BdcFeeMenuFunc = (props) => {
  const { anchorEl, menuIsOpen, closeMenu, openMenu } = useMenu();
  const { toggleToast, toastMessage } = useToast();

  const bdcUpdateDialog = useDailog();

  return (
    <div>
      <IconButton onClick={openMenu}>
        <MoreVert />
      </IconButton>
      <Menus anchorEl={anchorEl} open={menuIsOpen} closeMenu={closeMenu}>
        <div>
          <MenuItem onClick={bdcUpdateDialog.openDialog}>
            Update Bdc Payments
          </MenuItem>
        </div>
      </Menus>
      <UpdateBdcFeeDialog
        refetch={props.refetch}
        bdcInfo={{
          bdcId: props.bdcInfo?.bdcId,
        }}
        toggleToast={toggleToast}
        isOpen={bdcUpdateDialog.isOpen}
        closeDialog={bdcUpdateDialog.closeDialog}
      />
      {Boolean(toastMessage) && (
        <Toast
          open={Boolean(toastMessage)}
          message={toastMessage}
          onClose={() => toggleToast("")}
        />
      )}
    </div>
  );
};

export default BdcFeeMenu;
