import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import StickyHeadTable from "Components/Shared/table";
import Wrapper from "Components/Wrapper";
import { useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { getQueryString, request, toLocaleDate, toLocaleDateOnly } from "Utils";

import { useQuery } from "react-query";
import NoContentFound from "Components/Shared/noContentFound";
import * as XLSX from "xlsx"; // Import SheetJS library
import { ExchangeFeeHistoryInt, GetBdcFunc } from "./type";
import BdcFeeMenu from "./menu";
import { useDailog, useMenu, useToast } from "Utils/hooks";
import BdcFeeFilter from "./debitFilter";

const GetDebitBdcFee = () => {
  const [getQuery] = useSearchParams();
  const bdcId = getQuery.get("bdcId");
  const toCurrency = getQuery.get("toCurrency");

  const [filter, setFilter] = useState("");
  const [pageId, setPageId] = useState(1);
  const [limit, setLimit] = useState(10);
  const getStartDate = getQuery.get("startDate");
  const getEndDate = getQuery.get("endDate");
  const [startDate, setStartDate] = useState<string | null>(
    getQuery.get("startDate")
  );
  const [endDate, setEndDate] = useState<string | null>(
    getQuery.get("endDate")
  );

  const { toggleToast, toastMessage } = useToast();
  const { isOpen, openDialog, closeDialog } = useDailog();
  const getExchangeFeeHistory = async ({ queryKey }: any) => {
    const [, { pageId, limit, filter }] = queryKey;
    const query = { pageId, limit, startDate, endDate };
    const startDateFilter = getStartDate
      ? `&startDate=${encodeURIComponent(getStartDate)}`
      : "";
    const endDateFilter = getEndDate
      ? `&endDate=${encodeURIComponent(getEndDate)}`
      : "";
    if (bdcId && toCurrency) {
      const queryParams = getQueryString(query);
      const { data }: any = await request.get({
        url: `/bdc/exchange-fee-history${queryParams}&type=DEBIT&toCurrency=${encodeURIComponent(
          toCurrency
        )}&bdcId=${encodeURIComponent(
          bdcId
        )}${startDateFilter}${endDateFilter}`,
      });
      return data;
    } else {
      const queryParams = getQueryString(query);
      const { data }: any = await request.get({
        url: `/bdc/exchange-fee-history?type=DEBIT${startDateFilter}${endDateFilter}`,
      });
      return data;
    }
  };

  const bdcUpdateDialog = useDailog();
  const query = useLocation();
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["ExchangeFeeHistory", pageId, limit, query.search],
    queryFn: getExchangeFeeHistory,
    retry: 2,
  });

  const columns = [
    { minWidth: 20, name: "S/N", key: "index" },

    { minWidth: 30, name: "Order reference", key: "reference" },
    { minWidth: 30, name: "Name", key: "name" },
    { minWidth: 50, name: "From Currency", key: "fromCurrency" },
    { minWidth: 50, name: "To Currency", key: "toCurrency" },
    { minWidth: 50, name: "Amount", key: "amount" },
    { minWidth: 50, name: "Fee", key: "bdcFee" },
    { minWidth: 30, name: "Type", key: "type" },
    { minWidth: 50, name: "Created At", key: "createdAt" },
    { minWidth: 50, name: "Action", key: "action" },
  ];
  const bdcLists = data?.map(
    (feeHistory: ExchangeFeeHistoryInt, index: number) => ({
      index: ++index + limit * (pageId - 1),
      ...feeHistory,
      amount: `${feeHistory?.fromCurrency}${feeHistory?.amount.toLocaleString(
        "en"
      )}`,
      rate: feeHistory?.rate,
      fromCurrency: `${feeHistory?.fromCurrency}`,
      toCurrency: feeHistory?.toCurrency,
      //   status: feeHistory?.status,
      name: feeHistory?.bdcName,
      type: feeHistory?.type,
      // type: feeHistory?.status,
      bdcFee: `${feeHistory?.currency} ${feeHistory?.bdcFee}`,
      //   bdcId: feeHistory?.bdcId,
      createdAt: toLocaleDateOnly(feeHistory?.createdAt),
      reference: feeHistory.globalId,
      action: (
        <BdcFeeMenu
          bdcInfo={{ bdcId: feeHistory?.bdcId }}
          refetch={refetch}
        ></BdcFeeMenu>
      ),
    })
  );
  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      data.map((request: ExchangeFeeHistoryInt) => ({
        "Order Reference": request?.globalId,
        "BDC Name": request.bdcName,
        "BDC Id": request?.bdcId,
        "From Currency": request?.fromCurrency,
        "To Currency": request?.toCurrency,
        Amount: `${request?.fromCurrency}${request?.amount}`,
        Fee: `${request?.currency}${request?.bdcFee}`,
        Status: request?.status,
        Type: request?.type,
        "Created At": toLocaleDate(request.createdAt),
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Approved KYC Requests");
    XLSX.writeFile(workbook, `Exchange_Fee_History_${bdcId || ""}.xlsx`); //Adds bdcId to file if it exists
  };
  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Wrapper>
      <Container maxWidth="xl" sx={{ height: "85.5vh" }}>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Box display="flex" gap={2}>
            <BdcFeeFilter setFilter={setFilter}></BdcFeeFilter>
            <Button
              style={{ height: "5rem" }}
              variant="contained"
              color="primary"
              onClick={downloadExcel}
            >
              Download Excel
            </Button>
          </Box>
        </Box>
        {bdcLists?.length ? (
          <StickyHeadTable
            data={bdcLists}
            dataLength={bdcLists?.length}
            columns={columns}
            setLimit={setLimit}
            limit={limit}
            setPageId={setPageId}
            pageId={pageId}
          />
        ) : (
          <NoContentFound />
        )}
      </Container>
    </Wrapper>
  );
};

export default GetDebitBdcFee;
