import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputField from "Components/Shared/textField";
import { useDailog, useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { getQueryString, request } from "Utils";
import { OrderFilterFunc } from "./type";
import { useEffect, useState } from "react";
import { ConfigsInt } from "Components/Configuration/type";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";

type TimePeriod =
  | "all"
  | "last7days"
  | "thisMonth"
  | "lastMonth"
  | "thisQuarter"
  | "lastQuarter"
  | "customRange";

const OrderFilterAlign: OrderFilterFunc = ({ setFilter }) => {
  const { openDialog } = useDailog();
  const { getData, values, submit } = useForm(submitForm);
  const [countries, setCountries] = useState<Array<ConfigsInt>>();
  const [timePeriod, setTimePeriod] = useState<TimePeriod>("all");
  const [customStartDate, setCustomStartDate] = useState<string>("");
  const [customEndDate, setCustomEndDate] = useState<string>("");
  const query = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let params;
    params = query.search?.split("?")[1];
    if (params) {
      params = params?.split("&");
      for (const item of params) {
        const key = item.split("=");
        values[key[0]] = key[1];
      }
    }
  }, [query.search]);

  useEffect(() => {
    listCountries();
  }, [setFilter]);
  const listCountries = async () => {
    try {
      const { data } = await request.get({ url: "/configurations" });
      setCountries([...data]);
      openDialog();
    } catch (error) {}
  };

  function submitForm() {
    navigate(
      `/orders${getQueryString({
        ...values,
        fromCurrency: values?.fromCurrency?.split("-")[0] ?? "",
        toCurrency: values?.toCurrency?.split("-")[0] ?? "",
      })}`,
      { replace: true }
    );
  }
  const handleCustomRange = (e: any) => {
    const now = new Date();
    let periodStart = new Date();
    const targetName = e.target.name;
    const inputValue = e.target.value
      ? e.target.value
      : now.toISOString().split("T")[0];

    switch (targetName) {
      case "startDate":
        setCustomStartDate(inputValue);

        periodStart.setTime(new Date(inputValue).getTime());
        e.target.value = periodStart.toISOString().split("T")[0];
        getData(e);
        break;
      case "endDate":
        setCustomEndDate(inputValue);
        now.setTime(new Date(inputValue).getTime());
        e.target.value = now.toISOString().split("T")[0];

        getData(e);
        break;

      default:
        break;
    }
  };
  const handleTimeChange = (e: any) => {
    setTimePeriod(e.target.value as TimePeriod);
    const selectedPeriod = e.target.value as TimePeriod;

    const now = new Date();
    let periodStart = new Date();

    switch (selectedPeriod) {
      case "last7days":
        periodStart.setDate(now.getDate() - 7);
        break;
      case "thisMonth":
        periodStart.setDate(1);
        break;
      case "lastMonth":
        periodStart.setMonth(now.getMonth() - 1);
        periodStart.setDate(1);
        now.setDate(0);

        break;
      case "thisQuarter":
        periodStart.setMonth(Math.floor(now.getMonth() / 3) * 3);
        periodStart.setDate(1);
        break;
      case "lastQuarter":
        periodStart.setMonth(Math.floor(now.getMonth() / 3) * 3 - 3);
        periodStart.setDate(1);
        break;
      case "customRange":
        break;
      default:
        periodStart.setTime(now.getTime());
        break;
    }

    const startEvent = {
      target: {
        name: "startDate",
        value: periodStart.toISOString().split("T")[0],
        type: "text",
        checked: false,
        id: "",
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>;

    const endEvent = {
      target: {
        name: "endDate",
        value: now.toISOString().split("T")[0],
        type: "text",
        checked: false,
        id: "",
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>;
    getData(startEvent);
    getData(endEvent);
  };

  const status = ["PENDING", "SUCCESSFUL", "CANCELLED", "FAILED", "PROCESSING"];
  const order = ["desc", "asc"];
  return (
    <div>
      {/* <MenuOutlinedIcon onClick={listCountries} sx={{fontSize: 40, mb: 3, cursor: "pointer"}} />
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Filter"
        maxWidth="sm"
      > */}
      <form onSubmit={submit}>
        <Stack spacing={3} py={3}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <InputField
              type="text"
              label="Order Reference"
              name="globalId"
              onChange={getData}
              value={values.globalId || ""}
              sx={{ width: { xs: "100%", md: "12%" }, mb: 3 }}
            />
            <FormControl sx={{ width: { xs: "100%", md: "12%" }, mb: 3 }}>
              <InputLabel>Order Status</InputLabel>
              <Select
                name="status"
                labelId="status"
                label="Select status"
                // value={"PERCENTAGE" || "FIXED" || "TEXT"}
                onChange={(e: any) => getData(e)}
                value={values.status || ""}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {status?.map((status, index) => (
                  <MenuItem key={`${index}-status`} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: { xs: "100%", md: "12%" }, mb: 3 }}>
              <InputLabel>Order</InputLabel>
              <Select
                name="order"
                labelId="Order"
                label="Select order"
                // value={"PERCENTAGE" || "FIXED" || "TEXT"}
                onChange={(e: any) => getData(e)}
                value={values.order || ""}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {order?.map((order, index) => (
                  <MenuItem key={`${index}-order`} value={order}>
                    {order}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: { xs: "100%", md: "12%" }, mb: 3 }}>
              <InputLabel>From Currency</InputLabel>
              <Select
                labelId="Currency"
                label="From Currency"
                name="fromCurrency"
                onChange={(e: any) => {
                  getData(e);
                }}
                value={values.fromCurrency || ""}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {countries?.map((country, index) => {
                  return (
                    <MenuItem
                      key={`${index}-from-currency`}
                      value={`${country.currency}- ${country.iso2}`}
                    >
                      {country.country}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ width: { xs: "100%", md: "12%" }, mb: 3 }}>
              <InputLabel>To Currency</InputLabel>
              <Select
                labelId="Currency"
                label="To Currency"
                name="toCurrency"
                onChange={(e: any) => getData(e)}
                value={values.toCurrency || ""}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {countries?.map((country, index) => (
                  <MenuItem
                    key={`${index}-to-currency`}
                    value={`${country.currency}- ${country.iso2}`}
                  >
                    {country.country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="time-period-label">Time Period</InputLabel>
              <Select
                labelId="time-period-label"
                value={timePeriod}
                name="timePeriod"
                onChange={handleTimeChange}
                label="Time Period"
              >
                <MenuItem value="all">All Time</MenuItem>
                <MenuItem value="last7days">Last 7 Days</MenuItem>
                <MenuItem value="thisMonth">This Month</MenuItem>
                <MenuItem value="lastMonth">Last Month</MenuItem>
                <MenuItem value="thisQuarter">This Quarter</MenuItem>
                <MenuItem value="lastQuarter">Last Quarter</MenuItem>
                <MenuItem value="customRange">Custom Range </MenuItem>
              </Select>
            </FormControl>
            {timePeriod === "customRange" && (
              <>
                <TextField
                  label="Start Date"
                  type="date"
                  name="startDate"
                  value={customStartDate}
                  onChange={handleCustomRange}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="End Date"
                  name="endDate"
                  type="date"
                  value={customEndDate}
                  onChange={handleCustomRange}
                  InputLabelProps={{ shrink: true }}
                />
              </>
            )}
            <ActionButton
              type="submit"
              variant="contained"
              sx={{ width: { xs: "100%", md: "12%" }, mb: 3 }}
            >
              Filter
            </ActionButton>
          </Box>
        </Stack>
      </form>
      {/* </DialogComponent> */}
    </div>
  );
};

export default OrderFilterAlign;
