import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import StickyHeadTable from "Components/Shared/table";
import Wrapper from "Components/Wrapper";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getQueryString, request, toLocaleDate } from "Utils";
import NoContentFound from "Components/Shared/noContentFound";
import { GetBdcFunc, ListInt } from "./type";
import { Container } from "@mui/material";
import BdcMenu from "./menu";
import BdcListFilter from "./filter_align";
import { useLocation, useNavigate } from "react-router-dom";

const getBdcList = async ({ queryKey }: any) => {
  const [, { pageId, limit, filter }] = queryKey;
  const query = { pageId, limit };
  const queryParams = filter
    ? `${filter}&pageId=${pageId}&limit=${limit || 10}`
    : getQueryString(query);
  const { data } = await request.get({
    url: `/bdc/list${queryParams}`,
  });
  return data;
};

const GetBdcList: GetBdcFunc = () => {
  const [pageId, setPageId] = useState(1);
  const [limit, setLimit] = useState(10);
  const [paginatedData, setPaginatedData] = useState([]);
  const navigate = useNavigate();
  const query = useLocation();
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["Users", { pageId, limit, filter: query.search }],
    queryFn: getBdcList,
    retry: 2,
  });

  useEffect(() => {
    if (data) {
      const startIndex = (pageId - 1) * limit;
      const endIndex = startIndex + limit;
      setPaginatedData(data?.data?.slice(startIndex, endIndex));
      console.log(paginatedData);
    }
  }, [data, pageId, limit]);

  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 20, name: "Bdc ID", key: "_id" },
    { minWidth: 20, name: "User ID", key: "userId" },
    { minWidth: 50, name: "Name", key: "name" },
    { minWidth: 50, name: "Status", key: "status" },
    { minWidth: 30, name: "Online Status", key: "isOnline" },
    { minWidth: 50, name: "Created At", key: "createdAt" },
    { minWidth: 50, name: "Action", key: "action" },
  ];
  const bdcLists = data?.data?.map((list: ListInt, index: number) => ({
    index: ++index + limit * (pageId - 1),
    ...list,
    isOnline: list?.isOnline ? "YES" : "NO",
    createdAt: toLocaleDate(list?.createdAt),
    action: <BdcMenu refetch={refetch} bdcInfo={list} />,
  }));
  console.log(bdcLists);
  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;

  return (
    <Wrapper>
      <Container maxWidth="lg" sx={{ height: "85.5vh" }}>
        <BdcListFilter></BdcListFilter>
        <div>
          {data?.data?.length ? (
            <StickyHeadTable
              refetch={refetch}
              data={bdcLists}
              dataLength={data?.totalCount}
              columns={columns}
              setLimit={setLimit}
              limit={limit}
              setPageId={setPageId}
              pageId={pageId}
              pageCount={data?.pageCount}
            />
          ) : (
            <NoContentFound />
          )}
        </div>
      </Container>
    </Wrapper>
  );
};

export default GetBdcList;
