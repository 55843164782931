import {
  Box,
  Container,
  Typography,
  useTheme,
  Button,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { request } from "Utils";
const UnsubscribePage = () => {
  const { userId } = useParams<{ userId: string }>();
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const mutation = useMutation(
    async () => {
      return await request.patch({
        url: `/user/${userId}/unsubscribe`,
        data: { unsubscribe: true },
      });
    },
    {
      onSuccess: ({ message }) => {
        setSuccess(true);
      },
      onError: (error: any) => {
        const apiMessage =
          error?.response?.data?.message || "An unexpected error occurred.";
        setErrorMessage(apiMessage);
      },
    }
  );

  const handleClick = () => {
    mutation.mutate();
  };
  const theme = useTheme();
  if (!userId) {
    return <Typography>Error: User ID is missing from the URL.</Typography>;
  }

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        background: `linear-gradient(0deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
      }}
    >
      <Box
        sx={{
          maxWidth: "sm",
          borderRadius: 4,
          background: "white",
          height: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: 3,
        }}
      >
        {success ? (
          <Typography variant="h6" gutterBottom>
            You have been succcessfully unsubscribed
          </Typography>
        ) : (
          <>
            {mutation.isLoading ? (
              <CircularProgress aria-label="Unsubscribing..." />
            ) : (
              <>
                <Typography variant="h6" gutterBottom>
                  Don't want to receive our email updates? You can always
                  resubscribe later if you change your mind.
                </Typography>
                {errorMessage && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginTop: 2 }}
                  >
                    {errorMessage}
                  </Typography>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClick}
                  disabled={mutation.isLoading}
                >
                  Unsubscribe
                </Button>
              </>
            )}
          </>
        )}
      </Box>
    </Container>
  );
};

export default UnsubscribePage;
