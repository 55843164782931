import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { useDailog, useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { getQueryString, request } from "Utils";
import { useEffect, useState } from "react";
import { ConfigsInt } from "Components/Configuration/type";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { BdcFeeFilterFunc } from "./type";

type TimePeriod =
  | "all"
  | "last7days"
  | "thisMonth"
  | "thisQuarter"
  | "customRange";

const BdcFeeFilter: BdcFeeFilterFunc = ({ setFilter }) => {
  const { openDialog } = useDailog();
  const { getData, values, submit } = useForm(submitForm);
  const [countries, setCountries] = useState<Array<ConfigsInt>>();

  const [timePeriod, setTimePeriod] = useState<TimePeriod>("all");
  const [customStartDate, setCustomStartDate] = useState<string>("");
  const [customEndDate, setCustomEndDate] = useState<string>("");
  const query = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let params;
    params = query.search?.split("?")[1];
    if (params) {
      params = params?.split("&");
      for (const item of params) {
        const key = item.split("=");
        values[key[0]] = key[1];
      }
    }
  }, [query.search]);
  const listCountries = async () => {
    try {
      const { data } = await request.get({ url: "/configurations" });
      setCountries([...data]);
      openDialog();
    } catch (error) {}
  };

  useEffect(() => {
    listCountries();
  }, [setFilter]);

  function submitForm() {
    navigate(`/bdc-fee-history/credit${getQueryString(values)}`, {
      replace: false,
    });
  }
  const handleCustomRange = (e: any) => {
    const now = new Date();
    let periodStart = new Date();
    const targetName = e.target.name;
    const inputValue = e.target.value
      ? e.target.value
      : now.toISOString().split("T")[0];

    switch (targetName) {
      case "startDate":
        setCustomStartDate(inputValue);

        periodStart.setTime(new Date(inputValue).getTime());
        e.target.value = periodStart.toISOString().split("T")[0];
        getData(e);
        break;
      case "endDate":
        setCustomEndDate(inputValue);
        now.setTime(new Date(inputValue).getTime());
        e.target.value = now.toISOString().split("T")[0];

        getData(e);
        break;

      default:
        break;
    }
  };
  const handleTimeChange = (e: any) => {
    setTimePeriod(e.target.value as TimePeriod);
    const selectedPeriod = e.target.value as TimePeriod;

    const now = new Date();
    let periodStart = new Date();

    switch (selectedPeriod) {
      case "last7days":
        periodStart.setDate(now.getDate() - 7);
        break;
      case "thisMonth":
        periodStart.setDate(1);
        break;
      case "thisQuarter":
        periodStart.setMonth(Math.floor(now.getMonth() / 3) * 3);
        periodStart.setDate(1);
        break;
      case "customRange":
        break;
      default:
        periodStart.setTime(now.getTime());
        break;
    }

    const startEvent = {
      target: {
        name: "startDate",
        value: periodStart.toISOString().split("T")[0],
        type: "text",
        checked: false,
        id: "",
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>;

    const endEvent = {
      target: {
        name: "endDate",
        value: now.toISOString().split("T")[0],
        type: "text",
        checked: false,
        id: "",
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>;
    getData(startEvent);
    getData(endEvent);
  };

  const status = ["OPEN", "SOLD", "CANCELLED"];
  const order = ["desc", "asc"];
  return (
    <div>
      <form onSubmit={submit}>
        <Stack spacing={3} py={3}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="time-period-label">Time Period</InputLabel>
              <Select
                labelId="time-period-label"
                value={timePeriod}
                name="timePeriod"
                onChange={handleTimeChange}
                label="Time Period"
              >
                <MenuItem value="all">All Time</MenuItem>
                <MenuItem value="last7days">Last 7 Days</MenuItem>
                <MenuItem value="thisMonth">This Month</MenuItem>
                <MenuItem value="thisQuarter">This Quarter</MenuItem>
                <MenuItem value="customRange">Custom Range </MenuItem>
              </Select>
            </FormControl>
            {timePeriod === "customRange" && (
              <>
                <TextField
                  label="Start Date"
                  type="date"
                  name="startDate"
                  value={customStartDate}
                  onChange={handleCustomRange}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="End Date"
                  name="endDate"
                  type="date"
                  value={customEndDate}
                  onChange={handleCustomRange}
                  InputLabelProps={{ shrink: true }}
                />
              </>
            )}
            <ActionButton type="submit" variant="contained">
              Filter
            </ActionButton>
          </Box>
        </Stack>
      </form>
      {/* </DialogComponent> */}
    </div>
  );
};

export default BdcFeeFilter;
